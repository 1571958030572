import React, { useState, useEffect, useContext } from "react";
import { useAlert } from "../../customHooks";
import { Link } from "react-router-dom";
import XLSX from "xlsx";

import Loading from "../../components/Loading";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Divider } from "@material-ui/core";

import { StoreContext } from "../../context/StoreContext";
import OrderList from "./components/OrderList";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  buttonGroup: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export default function OrderHomePage() {
  const { createAlert } = useAlert();
  const { makeRequest } = useContext(StoreContext);
  const classes = useStyles();

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());

  // Submit Order to Database
  const searchQuotes = () => {
    setLoading(true);
    makeRequest("post", "api", "/orders/search", { startDate, endDate })
      .then((res) => {
        console.log(res);
        setSearch(true);
        setLoading(false);
        setOrders(res.data);
      })
      .catch((error) => {
        createAlert(error);
      });
  };

  const downloadOrders = () => {
    setLoading(true);
    makeRequest("post", "api", "/orders/search", { startDate, endDate })
      .then((res) => {
        try {
          let excelData = res.data;
          let updatedData = [];

          excelData.forEach((x) => {
            let {
              products,
              customer,
              address,
              date,
              itemTotal,
              subTotal,
              salesTax,
              discount,
              deposit,
              totalDue,
              employee,
              delivery,
            } = x;
            let { name, email, addresses } = customer;
            let formattedDate = moment(date).format("MM/DD/YYYY");
            let invoiceNumber = moment(date).format("YYDDDkkmmssS");

            let globalobj = {
              "Ref#": invoiceNumber,
              "Customer Name": name,
              "Trans Date": formattedDate,
              "Customer Address": addresses[0]?.street,
              "Address 2": addresses[0]?.unit,
              City: addresses[0]?.city,
              State: addresses[0]?.state,
              Zipcode: addresses[0]?.zip,
              "Cust Email": email[0],
              Phone: customer.phone[0]?.number,
              "Sales Rep": employee,
            };

            let deliveryobj = {
              ShipAddrLine1: address?.street,
              ShipAddrLine2: address?.unit,
              ShipAddrCity: address?.city,
              ShipAddrState: address?.state,
              ShipAddrZipcode: address?.zip,
            };

            let firstObj = {
              Deposit: deposit,
            };

            let lastObj = {
              "Taxable Sale": itemTotal,
              "Sales Tax": salesTax,
              "Invoice Total": subTotal,
              Discount: discount,
              "Bal Forward": totalDue,
            };

            products.forEach((y, i) => {
              let { vendor, sku, title, price, quantity } = y;
              let productinfo = {
                "Prod vendor": vendor.name,
                "Item description": `${sku} ${title}`,
                Price: price,
                QTY: quantity,
                "Line Subtotal": price * quantity,
              };
              let obj = {};

              if (products.length === 1) {
                // Only Item
                obj = {
                  ...globalobj,
                  ...productinfo,
                  ...firstObj,
                  ...lastObj,
                };

                if (delivery) {
                  obj = { ...obj, ...deliveryobj };
                }
              } else if (i == 0) {
                // First Item
                obj = {
                  ...globalobj,
                  ...productinfo,
                  ...firstObj,
                };
                if (delivery) {
                  obj = { ...obj, ...deliveryobj };
                }
              } else if (i == products.length - 1) {
                // Last Item
                obj = {
                  ...globalobj,
                  ...productinfo,
                  ...lastObj,
                };
              } else {
                // Middle
                obj = { ...globalobj, ...productinfo };
              }
              updatedData.push(obj);
            });
          });

          let header = [
            "Ref#",
            "Customer Name",
            "Trans Date",
            "Customer Address",
            "Address 2",
            "City",
            "State",
            "Zipcode",
            "Cust Email",
            "Phone",
            "Sales Rep",
            "Prod vendor",
            "Item description",
            "Price",
            "QTY",
            "Line Subtotal",
            "Deposit",
            "ShipAddrLine1",
            "ShipAddrLine2",
            "ShipAddrCity",
            "ShipAddrState",
            "ShipAddrZipcode",
            "Taxable Sale",
            "Sales Tax",
            "Invoice Total",
            "Discount",
            "Bal Forward",
          ];

          let sheet = XLSX.utils.json_to_sheet(updatedData, { header });
          let wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, sheet, "New Products");
          XLSX.writeFile(wb, "SheetJSReactExport.xlsx");
        } catch (error) {
          console.log(error);
        }
      })
      .catch((error) => {
        createAlert(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    makeRequest("get", "api", "/orders/")
      .then((res) => {
        setSearch(true);

        setLoading(false);
        setOrders(res.data);
      })
      .catch((error) => {
        createAlert(error);
      });
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item className="flex flexBaseline" xs={12}>
        <h2 className="flexSpacer">Recent Orders</h2>
        <div className={classes.buttonGroup}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to={"/Orders/Form"}
          >
            New Order
          </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
        spacing={3}
        style={{ display: "flex", gap: "1em", alignItems: "end" }}
      >
        <div>
          <p>Start</p>
          <input
            type="date"
            value={moment(startDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
        </div>
        <div>
          <p>End</p>
          <input
            type="date"
            value={moment(endDate).format("YYYY-MM-DD")}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
        </div>
        <Button variant="contained" color="primary" onClick={searchQuotes}>
          Search
        </Button>
        <Button variant="contained" color="primary" onClick={downloadOrders}>
          Export Customers
        </Button>
      </Grid>
      {search && (
        <React.Fragment>
          {loading ? (
            <Grid item xs={12}>
              <h1>Loading...</h1>
            </Grid>
          ) : (
            <OrderList orders={orders} />
          )}
        </React.Fragment>
      )}
    </Grid>
  );
}
